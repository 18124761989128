<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} {{ showLeads ? 'Lead' : 'Customer' }}</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <companies-house-lookup class="mb-2" :errors="errors" v-model="lookupDetails" />
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12">
                  <h2 class="font-weight-bold h4">Registered Address</h2>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_1')" :state="validationState(errors, 'address.address_line_1')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_1" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_2')" :state="validationState(errors, 'address.address_line_2')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_2" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'address.city')" :state="validationState(errors, 'address.city')">
                    <b-form-input :disabled="isSaving" v-model="address.city" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'address.county')" :state="validationState(errors, 'address.county')">
                    <b-form-input :disabled="isSaving" v-model="address.county" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'address.postcode')" :state="validationState(errors, 'address.postcode')">
                    <b-form-input :disabled="isSaving" v-model="address.postcode" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group label="Trustpilot Rating" :invalid-feedback="validationInvalidFeedback(errors, 'trustpilot')" :state="validationState(errors, 'trustpilot')">
                    <b-form-rating :disabled="isSaving" v-model="trustpilot" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="mb-2" cols="12">
              <h2>Company Logo (for Letter of Authority)</h2>
            </b-col>
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="text-center" cols="12" md="8">
                    <img class="img-responsive" :src="downloadURL(logoDocument && logoDocument.url)" style="max-height: 86px;" v-if="logoDocument">
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group :disabled="!$route.params.id" label="Upload Company Logo">
                      <b-form-file v-model="uploadCompanyLogo" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Customer Notes</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.add-customer-note v-if="currentUserCan(permissions.CAN_WRITE_ENERGY_CUSTOMER_NOTES)">Add Note</b-button>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-card class="py-2 text-center" v-if="!currentUserCan(permissions.CAN_READ_ENERGY_CUSTOMERS_HIDDEN)">
                    <p class="mb-2"><b-icon class="display-1 text-dark" icon="cloud-slash"></b-icon></p>
                    <p class="font-weight-normal h5 mb-0 text-dark">Can not view customer notes (you do not have permission).</p>
                  </b-card>
                  <b-table v-if="currentUserCan(permissions.CAN_READ_ENERGY_CUSTOMERS_HIDDEN)" responsive bordered striped head-variant="dark" :fields="['content', { key: 'created_at', label: 'Created' }, 'created_by']" :items="notes">
                    <template v-slot:cell(created_at)="row">
                      <friendly-date class="mb-0" :date="row.item.created_at" />
                    </template>
                    <template v-slot:cell(created_by)="row">
                      {{ [get(row.item, 'created_by.forename'), get(row.item, 'created_by.surname')].join(' ') }}
                    </template>
                  </b-table>
                  <b-modal v-if="currentUserCan(permissions.CAN_WRITE_ENERGY_CUSTOMER_NOTES)" id="add-customer-note" size="lg" cancel-title="Close"
                    title="Add Customer Note" ok-variant="secondary" cancel-variant="dark"
                    @ok.prevent="createCustomerNote" @cancel="cancelCustomerNote">
                    <template v-slot:modal-ok>
                      <span v-if="!isSavingNote">Create</span><b-spinner class="mx-4" small variant="light" v-if="isSavingNote" />
                    </template>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group class="mb-0" label="Customer Note">
                          <b-form-textarea :disabled="isSavingNote" rows="6" v-model="addCustomerNote" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-modal>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="4">
                  <h2>Scheduled Calls</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-pagination align="right" class="mb-0" @change="(page) => fetchScheduledCalls({
                    customer: this.$route.params.id,
                    page: page
                  })" :per-page="scheduledCallsPagination.per" :total-rows="scheduledCallsPagination.total" v-model="scheduledCallsPagination.current" v-if="scheduledCallsPagination.total > scheduledCallsPagination.per" />
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.schedule-call>Schedule Call</b-button>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['contact_name', 'contact_number', 'status', 'scheduled_for']" :items="scheduledCalls" v-if="!scheduledCallsLoading">
                    <template v-slot:cell(scheduled_for)="row">
                      {{ moment(row.item.scheduled_for).format('DD/MM/YYYY HH:mm') }}
                    </template>
                    <template v-slot:cell(status)="row">
                      {{ row.item.status && row.item.status.split('-').map(x => `${x.charAt(0).toUpperCase()}${x.slice(1)}`).join(' ') }}
                    </template>
                  </b-table>
                  <div class="text-center">
                    <b-spinner variant="secondary" v-if="scheduledCallsLoading"></b-spinner>
                  </div>
                </b-col>
              </b-row>
              <b-modal cancel-title="Cancel" cancel-variant="dark" ok-title="Complete" ok-variant="secondary"
                id="schedule-call" size="md" title="Schedule a Call" :ok-disabled="scheduledCallOkDisabled"
                @cancel="cancelScheduledCallModal" @ok.prevent="submitScheduledCallModal">
                <template v-slot:modal-ok>
                  <span v-if="!scheduledCallsCreating">Complete</span><b-spinner class="mx-4" small variant="light" v-if="scheduledCallsCreating" />
                </template>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Contact Details"
                      :invalid-feedback="[scheduledCallErrors.contact_name, scheduledCallErrors.contact_number].join('; ')"
                      :state="(scheduledCallErrors.contact_name && scheduledCallErrors.contact_name.length) ||
                        (scheduledCallErrors.contact_number && scheduledCallErrors.contact_number.length)">
                      <b-select text-field="name" :options="scheduledCallContacts" v-model="scheduledCallSelectedContact" class="mb-2"></b-select>
                      <b-form-input :disabled="scheduledCallSelectedContact !== 0" placeholder="Contact Name" v-model="scheduledCallContactName" class="mb-2" />
                      <b-form-input :disabled="scheduledCallSelectedContact !== 0" placeholder="Contact Number" v-model="scheduledCallContactNumber" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Scheduled For" :invalid-feedback="scheduledCallErrors.scheduled_for" :state="scheduledCallErrors.scheduled_for && scheduledCallErrors.scheduled_for.length">
                      <b-form-datepicker v-model="scheduledCallDate" class="mb-2"></b-form-datepicker>
                      <b-input-group>
                        <b-form-input v-model="scheduledCallTime"></b-form-input>
                        <b-input-group-append>
                          <b-form-timepicker button-only right v-model="scheduledCallTime"></b-form-timepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Sites</h2>
                </b-col>
                <b-col class="mb-2" cols="12" md="4">
                  <b-button block :disabled="!$route.params.id" :to="{ name: 'energy.sites.create', query: { customer: $route.params.id } }" variant="secondary">Add Site</b-button>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['address', 'supplies']" :items="sites">
                    <template v-slot:cell(address)="row">
                      {{ row.item.address.address_snippet }}
                    </template>
                    <template v-slot:cell(supplies)="row">
                      <div class="d-flex flex-row text-muted">
                        <b-icon class="mr-2" font-scale="1.5" icon="lightning-fill" :class="{'text-warning': row.item.meters.filter(m => m.type === 'electric').length}"></b-icon>
                        <b-icon class="mr-2" font-scale="1.5" icon="sun" :class="{'text-danger': row.item.meters.filter(m => m.type === 'gas').length }"></b-icon>
                        <b-icon class="mr-2" font-scale="1.5" icon="droplet-half" :class="{'text-info': row.item.meters.filter(m => m.type === 'water').length }"></b-icon>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="6">
                  <h2>Contacts</h2>
                </b-col>
                <b-col cols="6" md="2">
                  <b-button block :disabled="!contacts.length" :href="emailAllLink" variant="dark"><b-icon icon="envelope-open"></b-icon></b-button>
                </b-col>
                <b-col cols="6" md="4">
                  <b-button block @click.passive="editContact = {}" variant="primary" v-b-modal.edit-contact>Add Contact</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['name', 'email', 'phone', 'actions']" :items="contacts">
                    <template v-slot:cell(name)="row">
                      <p class="mb-0 text-nowrap">{{ [row.item.surname.toUpperCase(), row.item.forename].join(', ') }}</p>
                      <p class="mb-0 text-nowrap"><em>{{ row.item.position }}</em></p>
                    </template>
                    <template v-slot:cell(phone)="row">
                      <p class="mb-0" v-if="row.item.landline"><strong>Landline:</strong> {{ row.item.landline }}</p>
                      <p class="mb-0" v-if="row.item.mobile"><strong>Mobile:</strong> {{ row.item.mobile }}</p>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2 text-nowrap">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-button>
                      <b-button variant="dark" size="sm" :href="mailtoLinkFor(row.item)" class="mr-2 text-nowrap">Email Contact</b-button>
                    </template>

                    <template v-slot:row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Landline Phone Number:</strong></b-col>
                          <b-col>{{ row.item.landline || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Mobile Phone Number:</strong></b-col>
                          <b-col>{{ row.item.mobile || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Notes:</strong></b-col>
                          <b-col>{{ row.item.notes || 'N/A' }}</b-col>
                        </b-row>

                        <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">Hide Details</b-button>
                        <b-button variant="primary" size="sm" @click="showEditContact(row.index)" class="mr-2">Edit Contact</b-button>
                        <b-button variant="danger" size="sm" @click="removeContact(row.index)" class="mr-2">Remove</b-button>
                      </b-card>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-modal id="edit-contact" size="lg" cancel-title="Close" :ok-title="editContact.index < -1 ? 'Save' : 'Create'"
              :title="editContact.index < -1 ? 'Edit Contact' : 'Create Contact'" ok-variant="secondary" cancel-variant="dark"
              @ok="addContact" @cancel="cancelContact" :ok-disabled="contactSubmitDisabled">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Forename">
                    <b-form-input v-model="editContact.forename" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Surname">
                    <b-form-input v-model="editContact.surname" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Position">
                    <b-form-input v-model="editContact.position" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Email Address">
                    <b-form-input v-model="editContact.email" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Landline Number">
                    <b-form-input v-model="editContact.landline" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Mobile Number">
                    <b-form-input v-model="editContact.mobile" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Contact Notes">
                    <b-form-textarea v-model="editContact.notes" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="6">
                  <h2>Documents</h2>
                </b-col>
                <b-col cols="12" md="3">
                  <b-popover placement="top" target="generated-loas" triggers="click">
                    <b-button block :disabled="!$route.params.id" :href="`/api/energy-customers/${$route.params.id}/loa.pdf`" variant="secondary">Download Stage 1 LOA</b-button>
                    <b-button block :disabled="!$route.params.id" :href="`/api/energy-customers/${$route.params.id}/stage2-loa.pdf`" variant="secondary">Download Stage 2 LOA</b-button>
                  </b-popover>
                  <b-button block :disabled="!$route.params.id" id="generated-loas" variant="primary">Generated LOAs</b-button>
                </b-col>
                <b-col cols="12" md="3">
                  <b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.upload-document>Upload Document</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['filename', { key: 'url', label: 'URL' }, { key: 'created_at', label: 'Uploaded At' }]" :items="documents">
                    <template v-slot:cell(url)="row">
                      <a :href="downloadURL(row.item.url)">Download</a>
                    </template>
                    <template v-slot:cell(created_at)="row">
                      <friendly-date class="mb-0" :date="row.item.created_at"/>
                      <p class="mb-0"><em>{{ row.item.created_by ? `by ${[row.item.created_by.forename, row.item.created_by.surname].join(' ')}` : '' }}</em></p>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-modal id="upload-document" cancel-variant="dark" ok-variant="secondary" title="Upload Document" size="md"
               @ok.prevent="uploadCustomerDocument" @cancel="cancelUploadCustomerDocument">
                <template v-slot:modal-ok>
                  <span v-if="!isUploading">Upload</span><b-spinner class="mx-4" small variant="light" v-if="isUploading" />
                </template>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Upload Document">
                      <b-form-file v-model="uploadDocumentFile" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
          </b-row>
          <b-row v-if="currentUserCan(permissions.CAN_READ_ENERGY_CUSTOMERS_HIDDEN)">
            <b-col class="mb-2" cols="12" md="4" offset-md="8">
              <b-form-checkbox v-model="hidden">Hidden</b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2" cols="12" md="4" offset-md="8">
              <b-form-checkbox v-model="confirmed">Confirmed</b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="mb-2" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import clone from 'lodash/clone'
import normalizeURL from 'normalize-url'
import CompaniesHouseLookup from '../../components/CompaniesHouseLookup'
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import validation from '../../mixins/validation'
import differenceObject from '../../utils/diff'
import { mapActions, mapGetters } from 'vuex'

const initialData = () => ({
  uploadDocumentFile: null,
  uploadCompanyLogo: null,
  confirmed_at: null,
  confirmed: false,
  hidden: false,
  company_number: '',
  company_name: '',
  sole_trader: false,
  logo_upload_id: 0,
  trustpilot: 0,
  address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    county: '',
    postcode: ''
  },
  contacts: [],
  documents: [],
  notes: [],
  sites: [],

  // Modals
  addCustomerNote: '',
  editContact: {},

  scheduledCallDate: null,
  scheduledCallTime: '09:00:00',
  scheduledCallErrors: [],
  scheduledCallContactName: null,
  scheduledCallContactNumber: null,
  scheduledCallSelectedContact: 0
})

export default {
  components: {
    CompaniesHouseLookup,
    FriendlyDate
  },
  mixins: [currentUser, momentMixin, validation],
  mounted () {
    if (this.$route.params.id) {
      this.fetchCustomer(this.$route.params.id)
      this.fetchScheduledCalls({
        customer: this.$route.params.id,
        page: 1
      })
    }
  },
  data () {
    return initialData()
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'energy.customers.create' && to.name === 'energy.customers.update') {
      return next()
    }

    if (from.name === 'energy.leads.create' && to.name === 'energy.leads.update') {
      return next()
    }

    const differences = Object.keys(differenceObject(this.$data, this.single))
    const ignoreKeys = [
      'documents', 'notes', 'sites', 'addCustomerNote', 'editContact', 'scheduledCallDate', 'scheduledCallTime',
      'scheduledCallErrors', 'scheduledCallContactName', 'scheduledCallContactNumber', 'scheduledCallSelectedContact',
      'uploadDocumentFile', 'confirmed_at', 'confirmed'
    ]

    if (differences.filter(d => !ignoreKeys.includes(d)).length) {
      if (!confirm('You appear to have unsaved changes. Are you sure you want to leave this page?')) {
        return
      }
    }

    this.$store.dispatch('energy-customers/reset')
    this.$store.dispatch('scheduledCalls/reset')
    Object.assign(this.$data, initialData())
    return next()
  },
  computed: {
    ...mapGetters('energy-customers', ['errors', 'isLoading', 'isSaving', 'isSavingNote', 'isUploading', 'single']),
    ...mapGetters('scheduledCalls', {
      scheduledCallsPagination: 'pagination',
      scheduledCallsCreating: 'creating',
      scheduledCallsLoading: 'loading',
      scheduledCalls: 'data'
    }),
    logoDocument () {
      return this.documents.find(d => d.id === this.logo_upload_id)
    },
    lookupDetails: {
      get () {
        return {
          company_number: this.company_number,
          company_name: this.company_name,
          sole_trader: this.sole_trader,
          address: this.address
        }
      },
      set (value) {
        this.company_number = value.company_number
        this.company_name = value.company_name
        this.sole_trader = value.sole_trader
        this.address = value.address || {}
      }
    },
    scheduledCallOkDisabled () {
      return !this.scheduledCallContactName ||
        !this.scheduledCallContactNumber ||
        !this.scheduledCallTime ||
        !this.scheduledCallTime
    },
    scheduledCallContacts () {
      return [
        ...this.contacts.map(x => ({
          name: [x.forename, x.surname].join(' '),
          number: x.landline || x.mobile,
          value: x.id
        })),
        {
          name: '-- Manually enter information --',
          value: 0
        }
      ]
    },
    contactSubmitDisabled () {
      return !this.editContact.forename || !this.editContact.surname ||
             !this.editContact.email || !(
        (this.editContact.landline && this.editContact.landline.length === 11) ||
        (this.editContact.mobile && this.editContact.mobile.length === 11)
      )
    },
    emailAllLink () {
      const contacts = this.contacts.map(c => `${c.forename} ${c.surname} <${c.email}>`)
      return `mailto:${contacts.join(',')}?bcc=${process.env.VUE_APP_ADMIN_EMAIL}`
    }
  },
  props: {
    showLeads: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    get,
    ...mapActions('energy-customers', ['fetchCustomer', 'saveCustomer', 'createNote', 'uploadDocument']),
    ...mapActions('scheduledCalls', {
      fetchScheduledCalls: 'fetchData',
      createScheduledCall: 'createCall'
    }),
    cancelScheduledCallModal () {
      this.callsCompleted.splice(this.callsCompleted.indexOf(this.currentCallId, 1))
      this.$bvModal.hide('scheduled-call')
    },
    submitScheduledCallModal () {
      this.createScheduledCall({
        customer_id: this.$route.params.id,
        contact_name: this.scheduledCallContactName,
        contact_number: this.scheduledCallContactNumber,
        scheduled_for: this.moment(this.scheduledCallDate).add(this.scheduledCallTime).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        this.$bvModal.hide('schedule-call')
        this.fetchScheduledCalls({
          customer: this.$route.params.id,
          page: 1
        })
      }).catch(err => {
        this.scheduledCallErrors = err.response.errors
      })
    },
    downloadURL (path) {
      return path[0] === '/' ? normalizeURL(process.env.VUE_APP_API_URL + path) : path
    },
    uploadCustomerDocument () {
      const formData = new FormData()
      formData.append('file', this.uploadDocumentFile, this.uploadDocumentFile.name)
      this.uploadDocument({ id: this.$route.params.id, data: formData }).then(res => {
        this.documents.unshift(res.data)
        this.uploadDocumentFile = null
        this.$bvModal.hide('upload-document')
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Document uploaded successfully',
          text: 'The document has been assigned to this customer.'
        })
      }).catch(err => {
        if (err.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Error uploading document',
            text: `Error for debugging: ${err.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    cancelUploadCustomerDocument () {
      this.uploadDocumentFile = null
    },
    createCustomerNote () {
      this.createNote({
        customerId: this.$route.params.id,
        content: this.addCustomerNote
      }).then(response => {
        this.notes.unshift(response.data)
        this.addCustomerNote = ''
        this.$bvModal.hide('add-customer-note')
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Customer note created successfully',
          text: 'This energy customer has successfully been saved.'
        })
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer Note`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    cancelCustomerNote () {
      this.addCustomerNote = ''
    },
    addContact () {
      if (typeof this.editContact.index === 'undefined') {
        this.contacts.push(this.editContact)
      } else {
        this.contacts.splice(this.editContact.index, 1, this.editContact)
      }

      this.editContact = {}
      this.$bvModal.hide('edit-contact')
    },
    cancelContact () {
      this.editContact = {}
    },
    mailtoLinkFor (contact) {
      return `mailto:${contact.forename} ${contact.surname} <${contact.email}>?bcc=${process.env.VUE_APP_ADMIN_EMAIL}`
    },
    removeContact (index) {
      this.contacts.splice(index, 1)
    },
    showEditContact (index) {
      this.editContact = { ...this.contacts[index], index }
      this.contacts.splice(index, 1, this.editContact)
      this.$bvModal.show('edit-contact')
    },
    submit () {
      this.saveCustomer({
        id: this.$route.params.id,
        hidden: this.hidden,
        address: this.address,
        contacts: this.contacts,
        trustpilot: this.trustpilot,
        sole_trader: this.sole_trader,
        company_name: this.company_name,
        company_number: this.company_number,
        confirmed_at: this.confirmed ? this.moment((this.confirmed_at ? this.confirmed_at : this.moment())).format('YYYY-MM-DD HH:mm:ss') : null
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Customer ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This customer has successfully been saved.'
        })

        if (!this.$route.params.id) {
          return this.$router.push({ name: this.confirmed ? 'energy.customers.update' : 'energy.leads.update', params: { id: response.data.id } })
        }

        return this.fetchCustomer(this.$route.params.id)
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    uploadCompanyLogo (value) {
      if (value === null) {
        return
      }

      const formData = new FormData()
      formData.append('file', this.uploadCompanyLogo, this.uploadCompanyLogo.name)
      this.uploadDocument({ id: this.$route.params.id, data: formData }).then(res => {
        this.documents.unshift(res.data)
        this.uploadCompanyLogo = null
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Logo uploaded successfully',
          text: 'The logo has been assigned to this customer.'
        })

        return this.saveCustomer({
          company_name: this.company_name,
          logo_upload_id: res.data.id,
          id: this.$route.params.id,
          address: this.address
        }).then((res) => {
          this.logo_upload_id = res.data.logo_upload_id
        })
      }).catch(err => {
        if (err.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Error uploading logo',
            text: `Error for debugging: ${err.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    scheduledCallSelectedContact (value) {
      if (value !== 0) {
        const contact = this.scheduledCallContacts.find(x => x.value === value)
        this.scheduledCallContactName = contact.name
        this.scheduledCallContactNumber = contact.number
      }
    },
    single (value) {
      this.sole_trader = get(value, 'sole_trader')
      this.company_name = get(value, 'company_name')
      this.company_number = get(value, 'company_number')
      this.trustpilot = get(value, 'trustpilot')
      this.confirmed = !!get(value, 'confirmed_at')
      this.confirmed_at = get(value, 'confirmed_at')
      this.logo_upload_id = get(value, 'logo_upload_id')
      this.hidden = get(value, 'hidden')

      this.documents = clone(get(value, 'files', []))
      this.contacts = clone(get(value, 'contacts', []))
      this.address = clone(get(value, 'address', {}))
      this.notes = clone(get(value, 'notes', []))
      this.sites = clone(get(value, 'sites', []))
    }
  }
}
</script>

<style>

</style>
